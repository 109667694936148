<template>
  <div></div>
</template>

<script>
import ImagePreview from "image-preview-vue";
import "image-preview-vue/lib/imagepreviewvue.css";

export default {
  name: "ImagePreview",
  props: {
    images: {
      type: Array,
      required: true,
    },
    isBlobWithCustomProps: {
      type: Boolean,
      default: false,
    },
    additionalButtons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      config: {
        initIndex: 0,
        images: [],
        imagesWithAllAttr: [],
        isEnableBlurBackground: false,
        isEnableLoopToggle: true,
        initViewMode: "contain",
        containScale: 1,
        shirnkAndEnlargeDeltaRatio: 0.2,
        wheelScrollDeltaRatio: 0.2,
        isEnableImagePageIndicator: true,
        maskBackgroundColor: "rgba(0,0,0,0.7)",
        zIndex: 4000,
        isEnableDownloadImage: true,
      },
    };
  },
  mounted() {
    this.openImagePreview();

    if (this.additionalButtons.includes("delete")) {
      this.addDeleteButton();
    }

    this.$nextTick(() => {
      this.listenIndexChange();
    });
  },
  methods: {
    listenIndexChange() {
      const div = document.querySelector(".image-preview__page-info");

      div.addEventListener("DOMSubtreeModified", () => {
        let index = div.innerText.split("/")[0] - 1;

        if (this.additionalButtons.includes("span-name")) {
          this.addSpanName(this.imagesWithAllAttr[index].filename);
        }
      });

      if (this.additionalButtons.includes("span-name")) {
        this.addSpanName(this.imagesWithAllAttr[0].filename);
      }
    },
    openImagePreview() {
      let config = {};
      if (this.isBlobWithCustomProps) {
        this.config.images = this.images.map((image) => {
          return image.url;
        });
        this.imagesWithAllAttr = this.images;
      } else {
        this.config.images = this.images;
      }
      try {
        config = this.config;
      } catch (e) {
        this.showErrorSwal(e);
      }
      config.onClose = () => {
        if (this.isBlobWithCustomProps) {
          this.$emit("updateFiles", this.imagesWithAllAttr);
        }
        this.$emit("hidden");
      };
      const preview = ImagePreview(config);
    },
    addDeleteButton() {
      const actionBar = document.querySelector(".image-preview__action-bar");
      const div = document.createElement("div");
      div.setAttribute("data-v-04a34848", "");
      div.classList.add(
        "image-preview__full-scale-small",
        "action-btn",
        "image-preview__cir-action"
      );
      const iElement = document.createElement("i");
      iElement.setAttribute("data-v-04a34848", "");
      iElement.classList.add("zip", "trash-icon");
      div.appendChild(iElement);
      actionBar.appendChild(div);

      div.addEventListener("click", () => {
        const value = document.querySelector(
          ".image-preview__page-info"
        ).innerText;
        const index = value.split("/")[0] - 1;
        this.config.images.splice(index, 1);
        this.imagesWithAllAttr.splice(index, 1);
      });
    },

    addSpanName(title) {
      if (document.querySelector(".div-wrapper")) {
        document.querySelector(".div-wrapper").remove();
      }
      const actionBar = document.querySelector(".image-preview__action-bar");
      const divWrapper = document.createElement("div");
      divWrapper.classList.add("div-wrapper");
      const span = document.createElement("span");
      span.innerText = title;
      divWrapper.appendChild(span);
      actionBar.parentNode.insertBefore(divWrapper, actionBar);
    },
  },
};
</script>
<style >
.image-preview__page-info {
  background-color: #17171a !important;
  color: #ffffff !important;
  font-size: 1.5em !important;
  font-family: MONOSPACE !important;
}
.trash-icon {
  margin-left: -10px;
  width: 22px;
  height: 22px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="%23ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>')
    no-repeat;
  background-size: contain;
}

.div-wrapper {
  position: fixed;
  bottom: 10%;
  transform: translateY(50%) translateX(-50%);
  left: 50%;
  background-color: #17171a;
  padding: 1rem;
  border-radius: 15px;
  color: #ffffff;
  font-weight: 600;
}

.image-preview__cir-action {
  background-color: #17171a !important;
  color: #ffffff !important;
}

.zip zipprev {
  color: #ffffff !important;
}
</style>
